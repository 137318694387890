<template>
  <v-container fluid>
    <v-snackbar top :timeout="5000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0 mb-5">
      <v-col cols="12" sm="11" md="11">
        <h1>Configuración</h1>
      </v-col>
    </v-row>

    <h2 class="ml-5" style="color: #FF1744">Cambiar contraseña</h2>
    <v-form ref="form" lazy-validation>
      <v-row class="section">
        <v-col cols="12" sm="4" md="3"
          ><h4>Contraseña anterior</h4>
          <v-text-field
            v-model="password.old"
            :rules="[rules.required, rules.min]"
            autocomplete="new-password"
            :loading="loading"
            :type="show ? 'text' : 'password'"
            rounded
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="3"
          ><h4>Nueva contraseña</h4>
          <v-text-field
            v-model="password.new"
            :rules="[rules.required, rules.min]"
            :type="show ? 'text' : 'password'"
            autocomplete="new-password"
            rounded
            outlined
            :loading="loading"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="3"
          ><h4>Confirmar contraseña</h4>
          <v-text-field
            v-model="password.confirm"
            :type="show ? 'text' : 'password'"
            autocomplete="new-password"
            :rules="[rules.required, rules.min]"
            rounded
            outlined
            :loading="loading"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-icon @click="show = !show" style="cursor: pointer" class="mt-10">
            {{ show ? "fas fa-eye-slash" : "fas fa-eye" }}
          </v-icon>
        </v-col>
        <v-col cols="12" sm="4" md="2"
          ><v-btn
            id="new-pass-btn"
            :loading="loading"
            color="primary"
            block
            height="45"
            class="mt-7"
            >Cambiar</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <h2 class="ml-5" style="color: #FF1744">
      MFA Multifactor de Autenticación
    </h2>
    <v-row class="section">
      <v-col cols="12" sm="10" md="10">
        <h3 class="mt-3 font-weight-bold" v-if="!emailVerified">
          Para activar el factor de doble autenticación es necesario verificar
          su correo electrónico.
        </h3>
        <h3 v-else class="mt-3 font-weight-bold">
          MFA - Multifactor de Autenticación
          {{ user.mfa ? "activo" : "desactivado" }}
        </h3>
      </v-col>

      <v-col cols="12" sm="2" md="2">
        <v-btn
          absolute
          @click="sendVerificationEmail"
          :loading="sendingEmail"
          color="green"
          class="white--text mt-2"
          v-if="!emailVerified"
        >
          VERIFICAR EMAIL
        </v-btn>
        <v-btn
          v-else
          :loading="loading"
          :color="user.mfa ? 'red' : 'green'"
          block
          @click="user.mfa ? disableMFA() : showMFA()"
          class="white--text"
          height="45"
        >
          {{ user.mfa ? "Desactivar" : "Activar" }}</v-btn
        >
      </v-col>
    </v-row>

    <v-dialog persistent v-if="mfaDialog" v-model="mfaDialog" max-width="500px">
      <mfa @success="mfaSuccess" @cancel="mfaDialog = false" />
    </v-dialog>

    <v-dialog
      persistent
      v-if="disableMfaDialog"
      v-model="disableMfaDialog"
      max-width="500px"
    >
      <disable-mfa
        @success="disableMFAConfirmed"
        ref="disMfa"
        @cancel="disableMfaDialog = false"
      />
    </v-dialog>

    <v-dialog persistent v-if="otpDialog" v-model="otpDialog" max-width="500px">
      <otp
        :auth="authObject"
        ref="code"
        @cancel="otpDialog = false"
        @success="otpConfirmed"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { fb } from "@/firebase";
import { mapState } from "vuex";
import mfa from "./mfa";
import disableMfa from "./disableMFA";
import otp from "./insertCode.vue";

export default {
  name: "profile",
  components: {
    mfa,
    disableMfa,
    otp,
  },
  data() {
    return {
      loading: true,
      snackbar: "",
      snackbarText: "",
      loading: false,
      show: false,
      mfaDialog: false,
      disableMfaDialog: false,
      emailVerified: true,
      sendingEmail: false,
      authObject: null,
      verificationId: null,
      otpDialog: false,
      rules: {
        required: (value) => !!value || "Obligatorio",
        min: (v) => (v && v.length >= 8) || "Mínimo 8 caracteres",
      },
      password: {
        old: "",
        new: "",
        confirm: "",
      },
    };
  },
  computed: {
    ...mapState(["user"]),
  },

  methods: {
    showMFA() {
      if (!this.user.mfa) {
        this.mfaDialog = true;
      }
    },

    disableMFAConfirmed() {
      this.snackbarText = "MFA desactivado correctamente.";
      this.snackbar = true;
      this.disableMfaDialog = false;
    },

    disableMFA() {
      this.disableMfaDialog = true;
    },

    mfaSuccess() {
      this.snackbarText = "MFA activado correctamente.";
      this.snackbar = true;
      this.mfaDialog = false;
    },

    sendVerificationEmail() {
      this.sendingEmail = true;
      fb.auth()
        .currentUser.sendEmailVerification()
        .then(() => {
          this.snackbarText = `Se ha enviado un correo de confirmación a la dirección ${this.user.email}`;
          this.snackbar = true;
          this.sendingEmail = false;
        });
    },

    async otpConfirmed(otp) {
      try {
        var cred = await fb.auth.PhoneAuthProvider.credential(
          this.verificationId,
          otp
        );

        var multiFactorAssertion =
          await fb.auth.PhoneMultiFactorGenerator.assertion(cred);

        await this.authObject.resolveSignIn(multiFactorAssertion);

        const user = fb.auth().currentUser;
        await user.updatePassword(this.password.new);

        this.snackbarText = "Contraseña actualizada exitosamente.";
        this.snackbar = true;
        this.loading = false;

        fb.auth().signOut();
      } catch (error) {
        switch (error.code) {
          case "auth/invalid-verification-code":
            this.$refs.code.error();

            break;

          default:
            break;
        }
      }
    },

    async mfaValidation() {
      var phoneInfoOptions = {
        multiFactorHint: this.authObject.hints[0],
        session: this.authObject.session,
      };

      try {
        var phoneAuthProvider = new fb.auth.PhoneAuthProvider();

        this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );

        this.otpDialog = true;
      } catch (error) {
        recaptchaVerifier.render().then((widgetId) => {
          grecaptcha.reset(widgetId);
        });
      }
    },

    showMessage(msg) {
      this.snackbarText = msg;
      this.snackbar = true;
      grecaptcha.reset(window.recaptchaWidgetId);
    },

    async change() {
      if (this.$refs.form.validate()) {
        try {
          if (this.password.new != this.password.confirm) {
            this.showMessage("Las nuevas contraseñas no coinciden.");
            return;
          }

          this.loading = true;

          const user = fb.auth().currentUser;

          let credential = fb.auth.EmailAuthProvider.credential(
            this.user.email,
            this.password.old
          );

          await user.reauthenticateWithCredential(credential);
          await user.updatePassword(this.password.new);

          this.snackbarText = "Contraseña actualizada exitosamente.";
          this.snackbar = true;
          this.loading = false;

          fb.auth().signOut();
        } catch (error) {
          console.log(error);
          switch (error.code) {
            case "auth/multi-factor-auth-required":
              this.authObject = error.resolver;
              this.mfaValidation();
              break;

            case "auth/weak-password":
              this.showMessage(
                "La nueva contraseña es muy débil, ingrese una contraseña alfanumérica mayor a 8 dígitos."
              );
              break;

            case "auth/wrong-password":
              this.showMessage("La contraseña actual ingresada es incorrecta.");
              break;

            case "auth/too-many-requests":
              this.showMessage(
                "El acceso a esta cuenta ha sido deshabilitado temporalmente... configure su contraseña o puede intentarlo de nuevo más tarde."
              );
              break;

            default:
              this.showMessage(
                "Ocurrió un error desconocido, inténtelo nuevamente."
              );
              break;
          }

          // this.snackbar = true;
          this.loading = false;
        }
      } else {
        this.showMessage("Debe completar todos los campos.");
      }
    },
  },

  mounted() {
    this.$store.state.visibleSearch = false;

    this.emailVerified = fb.auth().currentUser.emailVerified;

    window.recaptchaVerifier = new fb.auth.RecaptchaVerifier("new-pass-btn", {
      size: "invisible",
      callback: async () => {
        this.change();
      },
    });

    recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  },
};
</script>

<style scoped>
.section {
  background-color: rgba(177, 177, 177, 0.195);
  padding: 10px;
  border-radius: 10px;

  margin: 15px;
}

.enabled {
  background-color: red;
}

.disabled {
  background-color: green;
}
</style>
