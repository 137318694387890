<template>
  <div>
    <v-snackbar absolute top :timeout="5000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline"> Multifactor de Autenticación </span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                rounded
                filled
                v-model="password"
                label="Ingrese su contraseña"
                type="password"
                autocomplete="new-password"
                :loading="loading"
                hide-details
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                rounded
                filled
                hide-details
                v-model="phoneNumber"
                :loading="loading"
                label="Ingrese su número de teléfono"
                placeholder="Ejem: +50496771316"
              />

              <div
                style="display: flex; justify-content: center"
                id="recaptcha-container"
                class="mt-5"
              ></div>
            </v-col>

            <v-col class="mb-0 pb-0" cols="12" sm="12" md="12">
              <v-btn
                :loading="loading"
                height="50"
                id="enable-mfa"
                rounded
                block
                color="primary"
                >Enviar Código</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog persistent v-if="otpDialog" v-model="otpDialog" max-width="500px">
      <otp
        ref="code"
        :phone="phoneNumber"
        @cancel="otpDialog = false"
        @success="next"
      />
    </v-dialog>
  </div>
</template>
  
  <script>
import { mapState } from "vuex";
import { fb, db } from "@/firebase";
import otp from "./insertCode.vue";

export default {
  name: "MFA",
  components: {
    otp,
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      phoneNumber: "",
      selectedCountry: "",
      password: "",
      loading: false,
      verificationId: "",
      recaptchaVerifier: null,
      otpDialog: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    showMessage(msg) {
      this.snackbarText = msg;
      this.snackbar = true;
      grecaptcha.reset(window.recaptchaWidgetId);
    },

    async next(otp) {
      try {
        let user = await fb.auth().currentUser;

        var cred = await fb.auth.PhoneAuthProvider.credential(
          this.verificationId,
          otp
        );

        var multiFactorAssertion =
          await fb.auth.PhoneMultiFactorGenerator.assertion(cred);

        await user.multiFactor.enroll(multiFactorAssertion);

        await db.collection("appUsers").doc(this.user[".key"]).update({
          mfa: true,
          mfaActivatedOn: new Date(),
          mfaPhoneNumber: this.phoneNumber,
        });

        this.otpDialog = false;
        this.$emit("success");
      } catch (error) {
        console.log(error);

        switch (error.code) {
          case "auth/invalid-verification-code":
            this.$refs.code.error();

            break;

          default:
            break;
        }

        this.snackbar = true;
      }
    },
    async send() {
      if (!this.password) {
        this.showMessage(
          "Debe ingresar la contraseña para realizar esta acción."
        );

        return;
      }

      if (!this.phoneNumber) {
        this.showMessage("Debe ingresar el número de teléfono a vincular.");
        return;
      }

      try {
        // this.loading = true;
        let user = fb.auth().currentUser;

        let credential = fb.auth.EmailAuthProvider.credential(
          this.user.email,
          this.password
        );

        await user.reauthenticateWithCredential(credential);

        let multiFactorSession = await user.multiFactor.getSession();

        var phoneInfoOptions = {
          phoneNumber: this.phoneNumber,
          session: multiFactorSession,
        };

        var phoneAuthProvider = new fb.auth.PhoneAuthProvider();

        // this.recaptchaVerifier = new fb.auth.RecaptchaVerifier(
        //   "recaptcha-container"
        // );

        this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );

        this.otpDialog = true;
      } catch (error) {
        console.log(error);

        // this.recaptchaVerifier.render().then(function (widgetId) {
        //   grecaptcha.reset(widgetId);
        // });

        // document.getElementById("recaptcha-container").innerHTML = "";

        switch (error.code) {
          case "auth/invalid-phone-number":
            // this.snackbarText = "Número de teléfono inválido.";
            this.showMessage("Número de teléfono inválido.");
            break;

          case "auth/unverified-email":
            this.showMessage(
              "Su correo electrónico no ha sido verificado, es necesario verificarlo para activar el factor de doble autenticación."
            );
            break;
          case "auth/weak-password":
            this.showMessage(
              "La nueva contraseña es muy débil, ingrese una contraseña alfanumérica mayor a 8 dígitos."
            );
            break;
          case "auth/wrong-password":
            this.showMessage("La contraseña ingresada es incorrecta.");
            break;

          case "auth/too-many-requests":
            this.showMessage(
              "El acceso a esta cuenta ha sido deshabilitado temporalmente... configure su contraseña o puede intentarlo de nuevo más tarde."
            );
            break;

          default:
            this.showMessage(error.message);
            break;
        }

        // this.snackbar = true;
        this.loading = false;
      }
    },
  },
  mounted() {
    window.recaptchaVerifier = new fb.auth.RecaptchaVerifier("enable-mfa", {
      size: "invisible",
      callback: async () => {
        this.send();
      },
    });

    recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  },
};
</script>
  

<style>
/* #recaptcha-container {
  display: flex;
  justify-content: center;
} */
</style>